<template>
  <div class="box">
    <div class="box-top">
      <span class="box-top_number">{{ number }}</span>
      <!-- <van-image :src="img" class="box-top_img" /> -->
      <div class="box-top_content">
        <span class="box-top_title">{{ universityName }}-{{ majorName }}</span>
      </div>
    </div>
    <van-row class="box-bottom" type="flex">
      <van-col class="box-bottom_item" span="12">
        <div class="box-bottom_title">{{ studentName }}</div>
        <div class="box-bottom_text">学生姓名</div>
      </van-col>
      <van-col class="box-bottom_item" span="12">
        <div class="box-bottom_title">{{ studentPhone }}</div>
        <div class="box-bottom_text">学生电话</div>
      </van-col>
    </van-row>
    <van-row class="box-bottom" type="flex">
      <van-col class="box-bottom_item" span="12">
        <div class="box-bottom_title">{{ collegeName }}</div>
        <div class="box-bottom_text">所属院系</div>
      </van-col>
      <van-col class="box-bottom_item" span="12">
        <div class="box-bottom_title">{{ majorLearningWay }}</div>
        <div class="box-bottom_text">学习方式</div>
      </van-col>
    </van-row>
    <van-row class="box-bottom" type="flex" justify="center">
      <van-col class="box-bottom_item" span="4.2">
        <div class="box-bottom_title">{{ politicalScore }}</div>
        <div class="box-bottom_text">政治</div>
      </van-col>
      <van-col class="box-bottom_item" span="4.2">
        <div class="box-bottom_title">{{ englishScore }}</div>
        <div class="box-bottom_text">外语</div>
      </van-col>
      <van-col class="box-bottom_item" span="4.2">
        <div class="box-bottom_title">{{ majorScore1 }}</div>
        <div class="box-bottom_text">专业一</div>
      </van-col>
      <van-col class="box-bottom_item" span="4.2">
        <div class="box-bottom_title">{{ majorScore2 }}</div>
        <div class="box-bottom_text">专业二</div>
      </van-col>
      <van-col class="box-bottom_item" span="4.2">
        <div class="box-bottom_title">{{ totalScore }}</div>
        <div class="box-bottom_text">总分</div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  name: "RankQueryItem",
  components: {

  },
  props: {
    img: {
      type: String,
      default: null,
    },
    number: {
      type: Number,
      default: 1,
    },
    universityProvince: {
      type: String,
      default: '北京',
    },
    universityName: {
      type: String,
      default: '大学',
    },
    collegeName: {
      type: String,
      default: '学院',
    },
    majorName: {
      type: String,
      default: '专业',
    },
    majorLearningWay: {
      type: String,
      default: '全日制',
    },
    politicalScore: {
      type: Number,
      default: '',
    },
    englishScore: {
      type: Number,
      default: '',
    },
    majorScore1: {
      type: Number,
      default: '',
    },
    majorScore2: {
      type: Number,
      default: '',
    },
    totalScore: {
      type: Number,
      default: '',
    },
    studentName: {
      type: String,
      default: '',
    },
    studentPhone: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  methods: {
  },
};
</script>

<style lang="less" scoped>
.box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
}

.box-top {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .box-top_number {
    min-width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    padding: 4px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: #FF6602;
    border-radius: 5px;
  }

  .box-top_img {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }

  .box-top_content {
    width: 100%;
    display: flex;
    flex-direction: column;

    .box-top_title {
      font-size: 15px;
      font-weight: 700;
      cursor: pointer;
    }
  }
}

.box-bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  .box-bottom_item {
    text-align: center;
  }

  .box-bottom_title {
    color: rgba(0, 0, 0, .64);
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 5px 0;
  }

  .box-bottom_text {
    color: rgba(0, 0, 0, .24);
    font-size: 12px;
  }
}

.province-img {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

::v-deep .van-grid-item__content {
  padding: 5px;
}
</style>
